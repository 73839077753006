import React from 'react';
import Layout from '../components/layouts/Layout';
import ContactComponent from '../components/Contact';
import SEO from '../components/seo/SEO';

const Contact: React.FC = () => {
    return (
        <Layout customSEO>
            <SEO title={'Contact'} />
            <ContactComponent />
        </Layout>
    );
};

export default Contact;
