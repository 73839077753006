import React from 'react';
import { fade, makeStyles, TextField } from '@material-ui/core';
import { navy, secondary } from '../util/theme';

const styles = makeStyles((theme) => ({
    root: {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: '#fcfcfb',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        width: '100%',
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(navy, 0.25)} 0 0 0 2px`,
            borderColor: navy,
        },
    },
    focused: {},
}));

const CustomInput: React.FC<any> = (props) => {
    const classes = styles();

    return <TextField variant="filled" fullWidth InputProps={{ classes, disableUnderline: true }} {...props} />;
};

export default CustomInput;
