import React from 'react';
import styled from 'styled-components';
import { rotate } from '../styles/Keyframe.styles';

const RotatingSvg = styled.svg`
	animation: ${rotate} 1s infinite linear;
`;

interface Props {
    color?: string;
}

const Spinner: React.FC<Props> = ({ color, ...props }) => {
    return (
        <RotatingSvg {...props} version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50'>
            <g>
                <radialGradient id='spinner' cx='25' cy='25' r='32.568' fx='56.5611' fy='25.0986' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.2811' style={{ stopColor: color ? color : '#ff5b9c', stopOpacity: 0 }}/>
                    <stop offset='0.5598' style={{ stopColor: color ? color : '#E6548A' }}/>
                    <stop offset='0.8889' style={{ stopColor: color ? color : '#F16E76' }}/>
                </radialGradient>
                <path fill="url(#spinner)" d='M25,2C12.3,2,2,12.3,2,25s10.3,23,23,23s23-10.3,23-23S37.7,2,25,2z M25,44C14.5,44,6,35.5,6,25 C6,14.5,14.5,6,25,6s19,8.5,19,19C44,35.5,35.5,44,25,44z'/>
            </g>
        </RotatingSvg>
    );
};

export default Spinner;
