import React from 'react';
import styled from 'styled-components';
import { modularScale } from '../util/modularScale';
import ContactForm from './ContactForm';

const Container = styled.div`
	width: 100%;
	max-width: 450px;
	margin: 0 auto 6rem;
	
	@media (min-width: 900px) {
	    padding: 0;
	}
`;

const Contact: React.FC = () => {
    return (
        <Container>
            <ContactForm />
        </Container>
    );
};

export default Contact;
