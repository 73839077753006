import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { Button } from '@material-ui/core';
import CustomInput from './CustomInput';
import { modularScale } from '../util/modularScale';
import { Link } from 'gatsby';
import { gradient } from '../util/theme';
import Spinner from './Spinner';
import axios, { handleError } from '../util/axios';
import { Alert } from 'antd';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';

const Container = styled.div`
	background: #fff;
	padding: 1rem;
	border-radius: 4px;
	
	@media (min-width: 450px) {
	    padding: 4rem;
	}
	
	@media (min-width: 900px) {
	    padding: 4rem;
	}
`;

const InputError = styled.span`
	color: red;
    font-size: ${modularScale(-1)};
`;

const Input = styled(CustomInput)`
	margin-bottom: 1rem;
`;

const Title = styled.h2`
	font-size: ${modularScale(1)};
	font-weight: bold;
	margin: 1rem 0 1rem;
	line-height: 1;
	
	@media (min-width: 900px) {
        margin: 0 0 1rem;
	}
`;

const Info = styled.div`
	margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
    color: #fff !important;
	background-color: #000;
	height: 42px;
	
	&.Mui-disabled {
	    background-color: #acacac;
	    
            &:hover, &:focus, &:active {
            background-color: #acacac;
        }
	}
	
	&:hover, &:focus, &:active {
	    background-color: #000;
	}
	
	svg {
	    width: 24px;
	}
`;

const ButtonText = styled.div`
	display: flex;
	align-items: center;
	
	svg {
	    width: 18px;
	    margin-left: 0.5rem;
	}
`;

const StyledAlert = styled(Alert)`
	margin-bottom: 1rem;
`;

const ContactForm: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [requestError, setRequestError] = useState('');
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async data => {
        setIsLoading(true);
        setIsDisabled(true);

        try {
            await axios.post('/contact', data);
            setIsSuccess(true);
        } catch (err) {
            handleError(err, () => {
                setRequestError('An error occurred while sending this message. Please try again later.');
            })
        }

        setIsLoading(false);
    }

    const getButtonText = () => {
        if (isSuccess) {
            return <ButtonText>Message sent <Icon path={mdiCheckCircleOutline} /></ButtonText>
        }

        if (isLoading) {
            return <Spinner color={'#fff'} />
        }

        return 'Send Message';
    }

    return (
        <Container>
            { requestError && <StyledAlert message={requestError} type="error"/> }

            <Title>Let's get in touch.</Title>

            <Info>This inbox is monitored weekly. If you have a song request, please submit it through the <Link to={'/requests'}>requests</Link> page!</Info>

            <form onSubmit={handleSubmit(onSubmit)}>
                {errors.name && <InputError>This field is required.</InputError>}
                <Input name="name" label="Your Name" type="text" size="small" inputRef={register({ required: true })}/>

                {errors.email && <InputError>This field is required.</InputError>}
                <Input name="email" label="Email" type="email" size="small" inputRef={register({ required: true })}/>

                {errors.subject && <InputError>This field is required.</InputError>}
                <Input name="subject" label="Subject" type="text" size="small" inputRef={register({ required: true })}/>

                {errors.message && <InputError>This field is required.</InputError>}
                <Input name="message" label="Message" type="text" size="small" multiline rows={10} inputRef={register({ required: true })}/>

                <StyledButton variant="contained" fullWidth type="submit" size="large" disableElevation disabled={isDisabled}>
                    {getButtonText()}
                </StyledButton>
            </form>
        </Container>
    );
};

export default ContactForm;
